import axios from "axios";
import qs from 'qs';
import { url, hostUrl } from "./url"
import { removeToken } from "./auth"
import router from "@/router";
axios.defaults.withCredentials = false;
import { getToken, setToken } from "@/utils/auth"
import {ElMessage} from "element-plus";
const instance = axios.create({
  baseURL: url,
  timeout: 50000
});

// 请求拦截器
instance.interceptors.request.use(
    config => {
      config.headers['Content-Type']="application/json";
      let token = getToken()
      config.headers['Authorization'] = 'Bearer' + ' ' + token
      return config
    },
    error => Promise.error(error)
)
// const defaultOpt = { login: true };
//
// function baseRequest(options) {
//   return instance(options).then(res => {
//     const data = res.data || {};
//     // if(data){
//     //     let code = data.code;
//     //     // if (code == '106') {
//     //     //     // to re-login
//     //     //     removeToken()
//     //     //     // window.location.href= location.href + 'login'
//     //     // }
//     //
//     // }
//     if (res.status !== 200) return Promise.reject({ msg: "请求失败", res, data });
//       console.log(data);
//       return Promise.resolve(data);
//   });
// }
//
// /**
//  * http 请求基础类
//  *
//  */
// const request = ["post", "put", "patch"].reduce((request, method) => {
//   /**
//    *
//    * @param url string 接口地址
//    * @param data object get参数
//    * @param options object axios 配置项
//    * @returns {AxiosPromise}
//    */
//   request[method] = (url, data = {}, options = {}) => {
//     // if(store.state.userInfo.token) data.token = store.state.userInfo.token;
//     return baseRequest(
//         Object.assign({ url, data, method }, defaultOpt, options)
//     );
//   };
//   return request;
// }, {});
//
// ["get", "delete", "head"].forEach(method => {
//   /**
//    *
//    * @param url string 接口地址
//    * @param params object get参数
//    * @param options object axios 配置项
//    * @returns {AxiosPromise}
//    */
//   request[method] = (url, params = {}, options = {}) => {
//     return baseRequest(
//         Object.assign({ url, params, method }, defaultOpt, options)
//     );
//   };
// });
// response interceptor
instance.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const config = response.config

        const res = response.data
        if (config.headers['Accept'] === 'text/html') {
            return res
        }
        if (typeof(res.code)!='undefined' && res.code && res.code != '200') {
            // Message({
            //     message: res.message || 'error',
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            // console.log(config);

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code == '106') {
                // to re-login
                removeToken()
                router.push({ path: '/login' })
            }
            ElMessage({
                showClose: true,
                message: res.message,
                center: true,
            })
            return Promise.reject(res.message || 'error')
        } else {
            if(config.url=='/service-admin/oauth/token'){
                return res
            }else{

                return res.data
            }

        }
    },
    error => {
        console.log('error ====> ' + error) // for debug
        return Promise.reject(error)
    }
)
export default instance;
