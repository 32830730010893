import router from "./router";

import { getToken, setToken } from "./utils/auth"

// 路由守卫
router.beforeEach((to, from, next) => {
    if (to.query.token) {
        setToken(to.query.token)
    }
    let token = getToken();
    // alert(token)
    if (to.path === '/login')  next()
    if (!token) {
        next({ path: '/login' })
    } else {
        next()
    }
})
